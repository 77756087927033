<template>
  <v-container class="flex-column mt-1 address-detail" v-if="internalValue">
    <div class="address-title">
      <h1>{{ titleSection }}</h1>
    </div>

    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <!-- v-if="!validateAddress" -->
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="internalValue.addressName"
            label="Identificativo *"
            outlined
            dense
            :rules="[requiredValue('Identificativo obbligatorio')]"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="internalValue.doorbellName"
            label="Nome sul citofono *"
            outlined
            dense
            :rules="[requiredValue('Nome sul citofono obbligatorio')]"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-select
            v-model="selectedProvince"
            item-text="Nome"
            item-value="Sigla"
            label="Provincia *"
            :rules="[requiredValue('Provincia obbligatoria')]"
            required
            outlined
            dense
            return-object
            :items="provinceList"
            @change="selectProvince"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <!-- <v-select
            v-model="internalValue.city"
            label="Località *"
            :rules="[requiredValue('Località obbligatoria')]"
            required
            outlined
            dense
            :items="cityList"
            item-text="Description"
            item-value="Description"
            :disabled="!internalValue.province"
          ></v-select> -->
          <v-autocomplete
            v-model="selectedCity"
            :search-input.sync="city"
            :items="cityList"
            return-object
            item-text="Nome"
            item-value="Nome"
            :disabled="!selectedProvince"
            :rules="[requiredValue('Città obbligatorio')]"
            auto-select-first
            clearable
            required
            outlined
            dense
            hide-no-data
            autocomplete="new_password"
            label="Località *"
            @change="selectCity"
          ></v-autocomplete>
          <!-- return-object -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="selectedAddress"
            :search-input.sync="address1"
            :items="addressList"
            item-text="Nome"
            item-value="Nome"
            :disabled="!selectedCity"
            :rules="[requiredValue('Indirizzo obbligatorio')]"
            auto-select-first
            clearable
            required
            outlined
            dense
            hide-no-data
            autocomplete="new_password"
            label="Indirizzo *"
            @change="selectAddress"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4" md="2">
          <v-text-field
            v-model="internalValue.addressNumber"
            label="Civico *"
            outlined
            dense
            :rules="[requiredValue('Civico obbligatorio')]"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="8" md="4">
          <v-select
            v-model="internalValue.postalcode"
            label="Cap *"
            :rules="[requiredValue('CAP obbligatorio')]"
            required
            outlined
            dense
            return-object
            :items="postalcodeList"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" md="3">
          <v-select
            :items="floorItems"
            v-model="internalValue.floor"
            label="Piano *"
            :rules="[requiredValue('Piano obbligatorio')]"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6" md="3">
          <v-switch
            v-model="internalValue.elevator"
            inset
            label="Ascensore"
            dense
            class="pr-4 pt-0"
            hide-details
          ></v-switch>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="internalValue.doorbellNumber"
            label="Scala/Interno"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="internalValue.referenceMobileNumber"
            label="Telefono *"
            outlined
            dense
            :rules="isPhoneNumberRules"
            v-mask="'##########'"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="internalValue.addressNote"
            label="Altre informazioni"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <span class="mandatory">* Campi obbligatori</span>
        </v-col>
      </v-row>
      <v-alert border="left" type="error" v-if="error">{{ $t(error) }}</v-alert>
    </v-form>
    <!-- v-if="!validateAddress" -->
    <v-row class="address-actions">
      <v-col cols="12" md="6" class="d-flex  justify-start">
        <v-btn
          color="primary"
          class="bg-white"
          to="/profile/addresses"
          large
          depressed
          outlined
        >
          Torna ai miei indirizzi
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end">
        <v-btn
          color="primary"
          class="primary-button"
          :disabled="enableSave"
          @click="geocodeAddress"
          large
          depressed
        >
          Verifica indirizzo
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped></style>
<script>
import GeoService from "~/service/geoService";
import DeliveryService from "~/service/deliveryService";
import EurospinCustomService from "@/service/eurospinCustomService";
import { requiredValue, isGenericPhone } from "@/validator/validationRules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import debounce from "lodash/debounce";

var vm;

export default {
  name: "ProfileAddressesEdit",
  // props: ["address", "newAddress"],
  data() {
    return {
      valid: false,
      lazy: true,
      error: null,
      internalValue: {
        addressName: null,
        doorbellName: null,
        city: null,
        postalcode: null,
        province: null,
        addressNumber: null,
        address1: null,
        doorbellNumber: null,
        floor: null,
        referenceMobileNumber: null,
        elevator: null
      },
      address: null,
      provinceList: [],
      cityList: [],
      addressList: [],
      postalcodeList: [],
      requiredValue: requiredValue,
      isPhoneNumberRules: [
        requiredValue("Telefono obbligatorio"),
        isGenericPhone()
      ],
      addressGeoCoded: null,
      validateAddress: false,
      floorItems: [
        "Piano terra",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "Oltre il sesto"
      ],
      selectedProvince: null,
      selectedCity: null,
      selectedAddress: null,
      city: null,
      address1: null
    };
  },
  directives: { mask },
  computed: {
    enableSave() {
      return !vm.valid;
    },
    titleSection() {
      return vm.internalValue.addressId
        ? vm.internalValue.addressName
        : "Inserisci Indirizzo";
    }
  },
  methods: {
    ...mapActions({
      setAddress: "cart/setAddress"
    }),
    async fetchProvinceList() {
      // vm.provinceList = await EurospinCustomService.getProvinceList();
      vm.provinceList = await EurospinCustomService.getPosteProvinceList();
    },
    selectProvince(province) {
      if (province.Id) {
        //vm.selectedProvince = province;
        vm.internalValue.province = province.Sigla;
        vm.resetCity();
        vm.resetAddress();
      }
    },
    async getCityList(filter, idProvince) {
      vm.cityList = await EurospinCustomService.getPosteCityList(
        filter,
        idProvince
      );
    },
    fetchCityList: debounce(function(val) {
      this.getCityList(val, vm.selectedProvince.Id);
    }, 400),
    selectCity(city) {
      if (city.Id != null) {
        //vm.selectedCity = city;
        vm.internalValue.city = city.Nome;
        vm.resetAddress();
      } else if (this.cityList.length > 0) {
        vm.selectedCity = this.cityList[0];
        this.selectCity(vm.selectedCity);
      }
    },
    resetCity() {
      vm.selectedCity = null;
      vm.internalValue.city = null;
      vm.cityList = [];
    },
    async getAddressList(address, cityId) {
      this.addressList = await EurospinCustomService.getPosteAddressList(
        address,
        cityId
      );
    },
    fetchAddressSuggestion: debounce(function(val) {
      this.getAddressList(val, this.selectedCity.Id);
    }, 400),
    selectAddress(address) {
      if (address.Id) {
        this.internalValue.address1 = address.Nome;
        this.postalcodeList = address.elencoCAP;
        if (address.elencoCAP.length == 1) {
          this.internalValue.postalcode = address.elencoCAP[0];
        }
      } else if (this.addressList.length > 0) {
        vm.selectedAddress = this.addressList[0];
        this.selectAddress(this.addressList[0]);
      }
    },
    resetAddress() {
      vm.selectedAddress = null;
      vm.addressList = [];
      vm.internalValue.address1 = null;
    },
    setDiscoveredAddress(address) {
      console.log(address);
      for (const [key] of Object.entries(address)) {
        vm.internalValue[key] = key ? address[key] : "";
      }
      this.fetchDefaultData();
    },

    async fetchDefaultData() {
      await vm.fetchProvinceList();
      if (vm.internalValue.province) {
        vm.selectedProvince = vm.provinceList.find(
          p => p.Sigla.toUpperCase() === vm.internalValue.province.toUpperCase()
        );
        if (vm.selectedProvince) {
          await vm.getCityList(vm.internalValue.city, vm.selectedProvince.Id);
          vm.selectedCity = vm.cityList.find(
            p => p.Nome.toUpperCase() === vm.internalValue.city.toUpperCase()
          );
          //continua con l'indirizzo
          if (vm.selectedCity) {
            await vm.getAddressList(
              vm.internalValue.address1,
              vm.selectedCity.Id
            );
            vm.selectedAddress =
              vm.addressList.length == 1
                ? vm.addressList[0]
                : vm.addressList.find(
                    p =>
                      p.Nome.toUpperCase() ===
                      vm.internalValue.address1.toUpperCase()
                  );
            vm.postalcodeList = vm.selectedAddress.elencoCAP;
          }
        }
      }
    },

    async getAddress(addressId) {
      vm.internalValue = await DeliveryService.getAddresById(addressId);
    },

    async geocodeAddress(e) {
      e.preventDefault();
      if (vm.$refs.form.validate()) {
        let isGecoded = false;
        if (vm.internalValue.latitude && vm.internalValue.longitude) {
          isGecoded = true;
        } else {
          let address = await GeoService.geocodeAddress(vm.internalValue);
          if (address) {
            Object.assign(vm.internalValue, address);
            isGecoded = true;
          }
        }

        if (isGecoded) {
          vm.internalValue.city = vm.internalValue.city.toUpperCase();
          vm.internalValue.province = vm.internalValue.province.toUpperCase();
          //vm.internalValue = address;
          // vm.addressGeoCoded = Object.assign({}, address, vm.internalValue);
          // vm.addressGeoCoded.city = vm.addressGeoCoded.city.formatted_address;
          // vm.addressGeoCoded.address1 =
          //   vm.addressGeoCoded.address1.formatted_address;

          // //? valido l'indirizzo geocodificato
          vm.validateAndSaveAddress();
        } else {
          vm.error = "message.invalidAddress1";
        }
      }
    },
    async validateAndSaveAddress() {
      var dataValidated = await DeliveryService.isValidAddress(
        vm.internalValue
      );
      //? se non e' valido mostro snakcbar errore
      if (dataValidated.active === 0) {
        vm.error = "message.invalidAddress1";
      } else {
        if (
          !vm.internalValue.address1 ||
          !vm.internalValue.addressNumber ||
          !vm.internalValue.postalcode ||
          !vm.internalValue.city
        ) {
          vm.error = "message.invalidAddress1";
        } else {
          const text =
            "E' stato trovato questo indirizzo: <b>" +
            vm.internalValue.address1 +
            " " +
            vm.internalValue.addressNumber +
            ", " +
            vm.internalValue.postalcode +
            " " +
            vm.internalValue.city +
            "</b>. Vuoi salvarlo?";
          const dialogRes = await this.$dialog.confirm({ text });
          // eslint-disable-next-line no-unused-vars
          // _this.$emit("removeAddress", address);
          if (dialogRes) {
            vm.saveAddress();
          }
        }
      }
    },
    async saveAddress() {
      var addressUpdated = await DeliveryService.updateAddress(
        vm.internalValue
      );
      if (addressUpdated) {
        //        var addresses = await DeliveryService.getAddressList();
        await vm.setAddress({
          addressType: "home",
          addressId: addressUpdated.addressId
        });
        vm.$router.back();
      }
    },
    resetForm() {
      if (vm.newAddress || vm.$route.params.newAddress) {
        vm.$refs.form.reset();
        for (const [key] of Object.entries(vm.internalValue)) {
          vm.internalValue[key] = key === "addressId" ? -1 : "";
        }
        if (vm.$route.params.discoveredAddress) {
          this.setDiscoveredAddress(vm.$route.params.discoveredAddress);
        }
      }
    }
  },
  watch: {
    address1(val, oldVal) {
      if (val && val.trim().length > 3 && val != oldVal) {
        vm.fetchAddressSuggestion(val);
      }
    },
    city(val, oldVal) {
      if (val && val.trim().length > 0 && val != oldVal) {
        vm.fetchCityList(val, vm.provinceId);
      }
    }
  },
  async created() {
    vm = this;
    if (vm.$route.params.addressId) {
      await vm.getAddress(vm.$route.params.addressId);
    }
    vm.fetchDefaultData();
  },
  mounted() {
    vm.resetForm();
  }
};
</script>
